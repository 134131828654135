import { Link, navigate } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import amplitude from "../utils/amplitude"
import classes from "./header.module.css"
import logo from "../images/seamless-logo.svg"

import { getCurrentUser, addCallback, removeCallback } from "../utils/auth"

class Header extends React.Component {
  state = {
    email: undefined,
  }

  componentDidMount() {
    this.mounted = true
    setTimeout(() => this.setState({ email: getCurrentUser().email }), 300)
    this.callbackId = addCallback("signIn", data => {
      if (this.mounted) {
        this.setState({ email: data.attributes.email })
      }
    })
  }

  componentWillUnmount() {
    this.mounted = false
    removeCallback("signIn", this.callbackId)
    this.callbackId = null
  }

  render() {
    return (
      <header className={classes.header}>
        <div className={classes.container}>
          <Link to="/">
            <img className={classes.logoImg} src={logo} />
          </Link>
          <div style={{ justifySelf: "flex-end" }}>
            {this.state.email ? (
              <div
                className={classes.avatar}
                onClick={() => navigate("/app/account")}
              >
                <div className={classes.avatarInner}>
                  {this.state.email.charAt(0).toUpperCase()}
                </div>
              </div>
            ) : (
              <div className={classes.buttonContainer}>
                <Link
                  className={classes.loginButton}
                  to="/app"
                  onClick={() => {
                    amplitude.logEvent("click login from header")
                  }}
                >
                  Login
                </Link>
                <div className={classes.ctaButtonContainer}>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={process.env.GATSBY_CHROME_WEB_STORE_LISTING_LINK}
                    className={classes.seamlessButton}
                    onClick={() => {
                      amplitude.logEvent("click add_free from header")
                    }}
                  >
                    Add to Chrome for free
                  </a>
                  <span className={classes.orText}>or</span>
                  <Link
                    to="/#pro"
                    className={classes.seamlessButton}
                    onClick={() => {
                      amplitude.logEvent("click get_pro from header")
                    }}
                  >
                    Get Pro
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>
      </header>
    )
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
